import Container from './container'

type Props = {
  data: {
    headingText: string
    icon: JSX.Element
    order: number
    paragraphText: string
  }[]
  uspHeading?: string
  itemHeadingClasses?: string
  itemParagraphClasses?: string
}

const USPSection = ({ data, uspHeading, itemHeadingClasses, itemParagraphClasses }: Props): JSX.Element => (
  <Container>
    {uspHeading && <h2 className="my-6 mb-20 text-3xl font-extrabold text-brand-secondary">{uspHeading}</h2>}
    <div className="flex flex-grow flex-col gap-20 md:flex-row">
      {data.map(content => (
        <div key={content.order} className="flex-1">
          {/*  eslint-disable-next-line prettier/prettier  */}
          <div className="relative mb-2 flex h-12 w-12 items-center justify-center bg-brand-tertiary-100">
            <span className="flex w-fit items-center rounded-sm bg-brand-primary-100 p-2 opacity-70" title={content.headingText}>
              {content.icon}
            </span>
          </div>
          <div className={itemHeadingClasses ?? 'min-h-16 text-2xl font-black'}>{content.headingText}</div>
          <p className={itemParagraphClasses ?? 'my-4 text-lg'}>{content.paragraphText}</p>
        </div>
      ))}
    </div>
  </Container>
)

export default USPSection
